* {
  padding: 0;
  margin: 0;
  background-color: rgb(0, 0, 0);
}
.play, .flex{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;

}

.side-nav{
  z-index: 2000;
}
.son {
  width: 320px;
  height:auto;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 1px 1px 10px rgb(85, 83, 83);
}
.series{
  width: 100%;
  height:auto;
  display: flex;
  align-items: center;
  gap:10px;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 1px 1px 10px rgb(85, 83, 83);
}
.series img{
  width: 430px;
  height: 210px;
  border-radius: 10px;
  padding: 10px;
}
.ser-content {
  color: white;
}
.ser-content p{
  color: rgb(208, 208, 208);
}
.stream{
  width: 400px;
  height:auto;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 1px 1px 10px rgb(85, 83, 83);
  margin-bottom:10px;
}
.songs{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.son img {
  width: 230px;
  height: 210px;
  border-radius: 10px;
  padding: 10px;
}
.stream img{
  width: 400px;
  height:312px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}
.son p ,.stream p{
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #b3a6a6;
}
.son a{
  text-decoration:none;
}
.son:hover , .stream:hover{
  transform: scale(1.05);
}
.son h2,.stream h2{
    margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #b3a6a6;
}

.somgg{
  padding: 100px;
}
@media (max-width: 768px) {

  .son {
      width: 125px;
      height:auto;
      margin-top: 30px;
  }
  .stream {
      width: 100%;
      height:auto;
      margin-top: 30px
  }

  .son img {
      width: 84%;
      /* height: 150px; */
      height: 180px;
  }
  .series img {
    width: 200px;
    height: 150px;
  }
  .ser-content p{
    margin-top: 15px;
    font-size: 10px;
  }
  .ser-content h2{
    margin-top: 0;
    font-size: 15px;
  }
  .stream img{
      width: 90%;
      height: 180px;
  }

  .son p,.stream p {
      margin-top: 3px;
      font-size: 14px;
  }
  

  .plr {
    width: 100%;
}
}


video {
  width: 100%;
  height: auto;
}
.vlc{
  color: rgb(244, 3, 3);
  margin-left: 10px;
  margin-right: 3px;
}
.vlc2{
  margin-left: 8px;
  color: rgb(183, 106, 255);
}
.bt{
  display: flex;
  justify-content: space-between;
  background-color: black;
  align-items: center;
  margin-top: 10px;
  flex-wrap:wrap;

}
.btt{
  height: 30px;
  color:  #b3a6a6;
  background-color: rgb(47, 45, 45);
  border: none;
  cursor: pointer;
  padding-top: 7px;
}

.search-bar {
  width: 92%;
  padding: 8px;
  margin-bottom: 16px;
  margin-left: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: white;
}



/* payment */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  h2{
    color: rgb(0, 0, 0);
    background-color: white;
  }
  h3{
    color: rgb(0, 0, 0);
    background-color: white;
  }
}

.qr-image {
  max-width: 200px;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.cancel-btn, .paynow-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn {
  background-color: red;
  color: white;
}

.paynow-btn {
  background-color: green;
  color: white;
}

  /* Fullscreen Login Container */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #121212;
    height:100vh;
    margin: 0;
    padding: 0;
  }
  
  /* Login Box */
  .login-box {
    background: #1f1f1f;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: white;
  }
  
  /* Heading */
  .login-box h2 {
    color: #f5a623;
    font-size: 24px;

    background-color: transparent;
  }
  
  /* Description */
  .login-box p {
    color: #bbb;
    font-size: 16px;
  }
  
  /* Google Sign-in Button */
  .login-box button {
    background: #f5a623;
    color: black;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .login-box button:hover {
    background: #e6951c;
  }
  
  
  .login-box img{
    width: 300px;
    height: 300px;
  }
  
  .message-actions{
    display:block;
    justify-content: space-between;
  }



  .cricket-scores {
  font-family: Arial, sans-serif;
  color: white;
  margin: 20px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #6287ad;
  border-radius: 5px;
}

.tab.active {
  background-color: #84c6f2;
}

.matches-list {
  display: flex;
  flex-wrap: wrap;
}

.matches-scroll {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;  /* For Internet Explorer 10+ */
}

.matches-scroll::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}


.match {
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  min-width: 200px;
  cursor: pointer;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(255, 215, 0, 0.6); 
  background-color: transparent;
}


.match:hover {
  transform: scale(1.05);
}

.match img {
  max-width: 100%;
  border-radius: 5px;
}

.match p {
  font-size: 14px;
}
.team-info,.team{
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
.match-status{
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
}
.match-header span{
  color: rgb(244, 136, 136);
}
.match-header h3{
  color: rgb(89, 115, 5);
}